/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .react-horizontal-scrolling-menu--scroll-container div.react-horizontal-scrolling-menu--item:not(:first-child) {
    margin:0 0 0 8px;
}
.scrolling-menus{
    padding: 26px 10px 16px;
}
.scrolling-menus.menu_template_2 {
  padding: 16px 10px 16px;
}