.taggBar {
  display: "flex" !important;
  justify-content: "left" !important;
  flex-wrap: "nowrap" !important;
  list-style: "none" !important;

  margin: 0 !important;
  overflow: "auto" !important;
  padding-left: "8px" !important;
  padding-right: "8px" !important;
}