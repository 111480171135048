@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;0,400;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900;1,400');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,300;0,900;1,400;1,700;1,900;1,400');

.navbar-4.w-nav.transparent-header {  
  background-color: transparent;
}
.career-slider{
  min-height: 400px;
    padding: 100px 100px 40px;
    background-color: transparent;
}
.career-slider .slick-next:before {
  content: "\e600";
  color:red;
  font-family: 'webflow-icons';
  color: #c7c7c7;
  font-size:40px;
}
.career-slider .slick-prev:before {
  content:"\e601";
  font-family: 'webflow-icons';
  color: #c7c7c7;
  font-size:40px;
  opacity:1;
}
.career-slider .slick-prev{
  left:-60px;
}
.career-slider .slick-dots li button:before{
  font-size: 20px;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-commerce-commercecartwrapper {
  display: inline-block;
  position: relative;
}

.w-commerce-commercecartopenlink {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecartopenlinkicon {
  margin-right: 8px;
}

.w-commerce-commercecartopenlinkcount {
  display: inline-block;
  height: 18px;
  min-width: 18px;
  margin-left: 8px;
  padding-right: 6px;
  padding-left: 6px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-color: #fff;
  color: #3898ec;
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
}

.w-commerce-commercecartcontainerwrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.8);
}

.w-commerce-commercecartcontainerwrapper--cartType-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftSidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightSidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
  position: absolute;
  top: 100%;
  left: 0px;
  right: auto;
  bottom: auto;
  background-color: transparent;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
  position: absolute;
  left: auto;
  top: 100%;
  right: 0px;
  bottom: auto;
  background-color: transparent;
}

.w-commerce-commercecartcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
}

.w-commerce-commercecartheader {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e6e6e6;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.w-commerce-commercecartheading {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.w-commerce-commercecartcloselink {
  width: 16px;
  height: 16px;
}

.w-commerce-commercecartformwrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecartform {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecartlist {
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
  -webkit-overflow-scrolling: touch;
}

.w-commerce-commercecartitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecartiteminfo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecartproductname {
  font-weight: 700;
}

.w-commerce-commercecartoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercecartquantity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecartquantity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecartfooter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
}

.w-commerce-commercecartlineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.w-commerce-commercecartordervalue {
  font-weight: 700;
}

.w-commerce-commercecartapplepaybutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 8px;
  height: 38px;
  min-height: 30px;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 8px;
  height: 38px;
}

.w-commerce-commercequickcheckoutgoogleicon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercequickcheckoutmicrosofticon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercecartcheckoutbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.w-commerce-commercecartemptystate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecarterrorstate {
  margin-top: 0px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  background-color: #ffdede;
}

.w-commerce-commerceaddtocartform {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.w-commerce-commerceaddtocartoptionpill {
  margin-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #000;
  border-bottom-color: #000;
  border-left-color: #000;
  border-right-color: #000;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  color: #000;
  background-color: #fff;
  cursor: pointer;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartquantityinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ddd;
}

.w-commerce-commerceaddtocarterror {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffdede;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercelayoutcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-commerce-commercelayoutmain {
  -webkit-flex-basis: 800px;
  -ms-flex-preferred-size: 800px;
  flex-basis: 800px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin-right: 20px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutblockcontent {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutemailinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.w-commerce-commercecheckoutcolumn {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecheckoutshippingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left-color: #e6e6e6;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-color: #e6e6e6;
  border-right-width: 1px;
  border-right-style: solid;
}

.w-commerce-commercecheckoutshippingmethoditem {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  margin-left: 12px;
  margin-right: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardnumber::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardexpirationdate::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardsecuritycode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  font-weight: 400;
  margin-left: 8px;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  margin-left: 16px;
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: pre-wrap;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  -webkit-flex-basis: 320px;
  -ms-flex-preferred-size: 320px;
  flex-basis: 320px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  text-align: center;
}

.w-commerce-commercecheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

/*.tagActive .MuiBox-root {
  background: grey !important;
}*/

.taggOverlay:before {
  background: rgba(0 0 0 / 40%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  position: absolute;
  z-index: 9;
}
.tagActive .MuiBox-root {
  position: relative;
  z-index: 99;
 
}

  
@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    margin-right: 0px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .w-commerce-commercelayoutsidebar {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecartcontainerwrapper--cartType-modal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercecartquantity {
    font-size: 16px;
  }

  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutemailinput {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingcountryselector {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardnumber {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardexpirationdate {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardsecuritycode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }
}

body {
  background-color: #000;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

p {
  margin-bottom: 10px;
}

a {
  color: #89f3ff;
  text-decoration: none;
}

a:hover {
  color: #89f3ff;
}

img {
  display: inline-block;
  max-width: 100%;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

figcaption {
  margin-top: 5px;
  text-align: center;
}

.tagg-intro-section {
  position: relative;
  padding-right: 15vw;
  padding-left: 15vw;
  background-image: url('../images/background-image.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.tagg-intro-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 90vh;
  padding-right: 0vw;
  padding-left: 0vw;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tagg-intro-container {
  width: 100%;
  padding-right: 35vw;
  padding-left: 0vw;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  padding-top: 0px;
}

.navlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  margin-right: 220px;
  padding: 8px 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 50px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.navlink:hover {
  background-image: linear-gradient(38deg, #861ae7, #1fe1ff);
}

.navlink.w--current {
  margin-right: 220px;
  color: #fff;
}

.navlink.w--current:visited {
  background-color: transparent;
  color: #fff;
}

.navlink._2 {
  margin-right: 0px;
  border-width: 2px;
  border-color: #696969;
  color: #4d4d4d;
  font-weight: 600;
}

.navlink._2:hover {
  border-color: #fff;
  background-color: #8d21fc;
  background-image: none;
  color: #fff;
}

.navbar {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: block;
  height: 70px;
  padding-top: 0px;
  padding-right: 5vw;
  padding-left: 15vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.background-image-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.body {
  overflow: visible;
  background-color: #000;
  color: #000;
}

.tagg-heading-1 {
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 0px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}


.tagg-paragraph {
  padding-bottom: 38px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  padding: 20px 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 50px;
  background-image: linear-gradient(22deg, #8017b1, #33eef5);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.interaction-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.image-2 {
  height: 60px;
  padding-left: 0px;
}

.text-span {
  font-size: 14px;
}

.tagg-mockup {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50vw;
  padding-right: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-3 {
  height: 70%;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom-bar {
  position: static;
  left: 15vw;
  top: auto;
  right: 15vw;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  margin-right: 24px;
  margin-left: 24px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.link {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

.careers-intro-section {
  height: auto;
}

.careers-into-container {
  position: relative;
  z-index: -2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 20vh;
  padding-bottom: 20vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/photo.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.career-intro-text {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  width: 70%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 20px;
}

.heading {
  background-image: linear-gradient(62deg, #89f3ff, #89f3ff);
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 110px;
  line-height: 170px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-block {
  padding-top: 16px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 50px;
  line-height: 80px;
  font-weight: 600;
  text-align: center;
}

.carerrs-statement {
  padding-right: 15vw;
  padding-left: 15vw;
}

.paragraph {
  padding-top: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 29px;
}

.our-values-section {
  display: block;
  margin-bottom: 82px;
  padding-right: 10vw;
  padding-left: 10vw;
}

.our-values-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.our-value-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 82px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.our-value-contents {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.value-content-text {
  width: 50%;
}

.value-content-images {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider {
  height: 540px;
  padding-right: 111px;
  padding-left: 111px;
  background-color: transparent;
}

.slide-title {
  padding-bottom: 24px;
  font-family: Montserrat, sans-serif;
}

.slide-paragraph {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.heading-2 {
  font-family: Montserrat, sans-serif;
  font-size: 70px;
  line-height: 90px;
}

.icon-2 {
  color: #c7c7c7;
}

.icon-3 {
  color: #c7c7c7;
}

.blur-glass-div {
  position: absolute;
  left: -5%;
  top: -5%;
  right: -5%;
  bottom: -5%;
  z-index: -1;
  width: auto;
  height: auto;
  border-radius: 20px;
  background-image: url('../images/photo.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.navbar-2 {
  top: 0px;
  display: block;
  height: 70px;
  padding-right: 15vw;
  padding-left: 15vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.body-2 {
  overflow: visible;
  background-color: transparent;
}

.brand-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.press-section {
  padding-right: 15vw;
  padding-left: 15vw;
}

.heading-3 {
  margin-top: 66px;
  padding-top: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#8d21fc), to(#7ac2ea));
  background-image: linear-gradient(180deg, #8d21fc, #7ac2ea);
  font-family: Montserrat, sans-serif;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.news {
  padding-right: 20vw;
  padding-left: 20vw;
}

.news-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 500px;
  padding-top: 40px;
}

.div-block {
  width: 50%;
}

.text-conatiner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.image-container {
  width: 50%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.news-logo {
  max-height: 120px;
}

.news-title-and-link {
  text-decoration: none;
}

.text-block-2 {
  font-family: Montserrat, sans-serif;
  color: #3b3b3b;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  text-decoration: none;
}

.text-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 70px;
  padding-bottom: 36px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 190px;
  height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-image: linear-gradient(34deg, #8b15fd, #6de6e6);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.image-4 {
  display: inline-block;
  height: auto;
  max-height: 100%;
}

.tagg-team-intro-container {
  height: auto;
  padding: 10vh 15vw;
  background-color: #f2f6f8;
}

.heading-4 {
  margin-bottom: 20px;
  background-image: linear-gradient(273deg, #86f6fa, #8d21fc);
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 63px;
  line-height: 70px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bold-text {
  font-family: Montserrat, sans-serif;
  color: #353535;
  font-size: 30px;
  line-height: 30px;
}

.text-block-3 {
  padding-top: 42px;
  padding-bottom: 0px;
  font-size: 20px;
}

.button-3 {
  border-radius: 50px;
}

.team-section {
  display: block;
  padding-right: 15vw;
  padding-left: 15vw;
}

.paragraph-2 {
  margin-bottom: 0px;
  padding-top: 67px;
  padding-bottom: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.paragraph-2.image {
  padding-right: 0vw;
}

.bold-text-2 {
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  line-height: 32px;
}

.div-block-2 {
  height: 330px;
  background-image: url('../images/ambassador-image-03.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.what-you-l-get-section {
  display: none;
  padding-right: 15vw;
  padding-left: 15vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.what-you-ll-get-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5vh;
}

.what-you-ll-get-container {
  width: 60%;
}

.what-you-ll-get-image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/iphone_logo.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.apply-now {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 103px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-bg {
  height: auto;
  padding: 1px 25vw 193px;
  background-image: linear-gradient(17deg, #8d21fc, #89f3ff);
}

.contact-container {
  padding-right: 0vw;
  padding-left: 0vw;
}

.text-field {
  border-style: solid;
  border-width: 2px;
  border-color: #89f3ff;
  border-radius: 10px;
  background-color: transparent;
  color: #7ac2ea;
}

.text-field.text {
  height: 250px;
  border-color: #89f3ff;
  background-color: hsla(0, 0%, 100%, 0.48);
}

.text-field-2 {
  border-style: solid;
  border-width: 2px;
  border-color: #89f3ff;
  border-radius: 10px;
  background-color: transparent;
}

.heading-5 {
  padding-top: 15vh;
  padding-bottom: 5vh;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

.heading-5._2 {
  padding-top: 8vh;
  padding-bottom: 3vh;
  font-size: 40px;
}

.submit-button {
  width: 140px;
  height: 60px;
  border-radius: 50px;
  background-color: #89f3ff;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.body-3 {
  background-color: transparent;
  background-image: linear-gradient(210deg, #8d21fc, #89f3ff);
}

.text-field-3 {
  border-radius: 10px;
}

.form-block {
  padding-top: 54px;
  padding-bottom: 79px;
}

.text-block-4 {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.terms-of-service-section {
  overflow: visible;
}

.terms-of-service-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 500px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(212deg, #8d21fc, #89f3ff);
  position: relative;
}

.tagg-text-01 {
  padding-right: 20vw;
  padding-left: 20vw;
}

.image-5 {
  position: absolute;
  z-index: 0;
  width: 60%;
}

.div-block-3 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60vw;
  height: 30vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(53, 53, 53, 0.63);
}

.div-block-4 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: auto;
  height: auto;
  background-image: linear-gradient(207deg, #8d21fc, #89f3ff);
}

.heading-6 {
  margin-bottom: 0px;
  color: #fff;
  text-align: left;
}

.bold-text-3 {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 60px;
}

.image-6 {
  width: 50%;
}

.paragraph-3 {
  padding-top: 31px;
  padding-bottom: 31px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 27px;
}

.paragraph-3._2 {
  padding-top: 28px;
  padding-bottom: 28px;
  color: #fff;
}

.bold-text-4 {
  font-family: Montserrat, sans-serif;
  color: #353535;
  font-size: 30px;
}

.bold-text-4._2 {
  color: #fff;
}

.heading-7 {
  padding-top: 62px;
  padding-bottom: 15px;
}

.tagg-text-02 {
  padding: 50px 20vw 100px;
  background-color: #698dd3;
}

.text-span-2 {
  text-align: left;
}

.read-pdf {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 62px;
  padding-bottom: 62px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #8d21fc;
  border-radius: 5px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: Montserrat, sans-serif;
  color: #8d21fc;
  font-size: 16px;
  font-weight: 700;
}

.button-4:hover {
  background-color: #8d21fc;
  color: #fff;
}

.success-message {
  height: 150px;
  border-radius: 20px;
  background-color: hsla(0, 0%, 86.7%, 0.28);
}

.text-block-5 {
  font-family: Montserrat, sans-serif;
  color: #89f3ff;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
}

.error-message {
  position: relative;
  top: -316px;
}

.image-7 {
  height: 70%;
}

.career-posting {
  margin-bottom: 0px;
  padding-right: 15vw;
  padding-bottom: 82px;
  padding-left: 15vw;
}

.jobs-div {
  padding: 17px 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #a7a7a7;
  border-radius: 13px;
}

.heading-8 {
  font-family: Montserrat, sans-serif;
  font-size: 34px;
  text-align: center;
}

.text-span-3 {
  color: #8f8f8f;
  font-size: 24px;
}

.collection-item {
  width: 100%;
  height: 120px;
  padding: 21px 16px;
  border-bottom: 1px solid #000;
}

.job-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 80%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-image: linear-gradient(214deg, #89f3ff, #8d21fc);
  text-decoration: none;
}

.text-block-6 {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.text-block-7 {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.body-4 {
  background-color: #000;
}

.image-8 {
  width: 120px;
  padding: 12px;
}

.nav {
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.nav:hover {
  color: #698dd3;
}

.nav.w--current {
  color: #698dd3;
}

.navbar-3 {
  background-color: transparent;
}

.section {
  margin-top: 42px;
  margin-bottom: 16px;
  background-color: #000;
  color: #000;
}

.heading-9 {
  margin-top: 50px;
  margin-bottom: 30px;
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 84px;
  text-align: center;
  text-decoration: underline;
}

.heading-9:hover {
  color: #89f3ff;
}

.text-block-8 {
  text-align: center;
}

.heading-10 {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 34px;
  font-weight: 400;
  text-align: center;
}

.heading-11 {
  margin-bottom: 30px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.div-block-6 {
  position: relative;
  display: block;
  overflow: visible;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#89f3ff), to(#8d21fc));
  background-image: linear-gradient(180deg, #89f3ff, #8d21fc);
}

.image-9 {
  position: static;
  height: 700px;
}

.div-block-7 {
  position: absolute;
  left: 40%;
  top: 40%;
  right: 0%;
  bottom: 0%;
  z-index: 3;
}

.image-10 {
  height: 55vw;
}

.div-block-8 {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.section-2 {
  margin-top: 16px;
  margin-bottom: 0px;
}

.grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -ms-grid-columns: 0.25fr 0.25fr;
  grid-template-columns: 0.25fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-3 {
  overflow: hidden;
  position: relative;
}

.lottie-animation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lottie-animation-2 {
  height: 55vh;
}

.image-11 {
  display: inline-block;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-12 {
  display: none;
}

.grid-2 {
  margin-top: 52px;
  margin-bottom: 52px;
  grid-row-gap: 24px;
  grid-template-areas: "Area Area Area Area Area Area";
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto 24px auto;
  grid-template-rows: auto auto;
}

.image-13 {
  height: 5vh;
}

.footer-link {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.section-4 {
  overflow: hidden;
  margin-top: 100px;
  margin-bottom: 52px;
}

.image-14 {
  height: 8vh;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-3 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-2 {
  margin-top: 52px;
  padding-bottom: 52px;
}

.image-15 {
  height: 8vh;
}

.container-3 {
  margin-bottom: 0px;
}

.background-video {
  width: auto;
  height: auto;
}

.div-block-11 {
  position: absolute;
}

.container-4 {
  position: absolute;
  z-index: 2;
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.image-16 {
  position: static;
}

.background-video-2 {
  width: 33vw;
  height: 85vh;
}

.background-video-3 {
  width: 60vh;
  height: 120vh;
  margin-top: 25vh;
}

.body-5 {
  background-color: #000;
}

.section-5 {
  background-image: url('../images/background-office-hrs.png');
  background-position: 0px 0px;
  background-size: auto;
}

.section-6 {
  padding-top: 250px;
  padding-bottom: 250px;
  background-image: url('../images/longer.png');
  background-position: 50% 50%;
  background-size: cover;
}

.container-5 {
  margin-top: 0px;
}

.div-block-12 {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #000;
}

.heading-12 {
  background-image: linear-gradient(114deg, #89f3ff, #8d21fc);
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-block-9 {
  font-family: Montserrat, sans-serif;
}

.text-block-10 {
  font-family: Montserrat, sans-serif;
  font-size: 74px;
  font-weight: 700;
  text-align: center;
}

.heading-13 {
  background-image: linear-gradient(245deg, #8d21fc, #89f3ff);
  font-family: Montserrat, sans-serif;
  color: #8d21fc;
  font-size: 74px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-14 {
  font-size: 74px;
}

.heading-15 {
  font-size: 74px;
}

.tagg-heading-1-gradient {
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 0px;
  background-image: linear-gradient(257deg, #8d21fc, #89f3ff);
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grid-4 {
  grid-column-gap: 16px;
  -ms-grid-columns: 0.25fr 1fr 0.25fr;
  grid-template-columns: 0.25fr 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-16 {
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 600;
}

.image-17 {
  width: 142px;
}

.paragraph-4 {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 500;
}

.container-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #353535;
  background-image: linear-gradient(159deg, #8d21fc, #89b8ff);
}

.section-7 {
  display: none;
  margin-top: 50px;
  margin-bottom: 0px;
  padding-bottom: 50px;
  font-size: 34px;
}

.grid-5 {
  display: none;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-6 {
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.div-block-13 {
  padding: 16px;
  border: 1px solid transparent;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #353535;
}

.div-block-14 {
  border-radius: 0px;
}

.image-18 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.text-block-11 {
  margin-right: 6px;
  font-family: Montserrat, sans-serif;
  color: #c7c7c7;
  font-size: 15px;
  font-weight: 500;
}

.blog-title-2 {
  margin-top: 42px;
  margin-bottom: 42px;
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  color: #fff;
  font-size: 34px;
  line-height: 34px;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.blog-title-2:hover {
  color: #89f3ff;
}

.grid-7 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-grid-columns: 1fr 0.25fr;
  grid-template-columns: 1fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-block-12 {
  margin-bottom: 16px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.text-field-4 {
  padding: 24px;
  border-radius: 1200px;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.field-label {
  font-family: Montserrat, sans-serif;
  color: #fff;
}

.button-blog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 190px;
  padding: 12px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-image: linear-gradient(22deg, #8017b1, #33eef5);
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-9 {
  background-color: #f2f6f8;
}

.section-8 {
  padding-right: 15vw;
  padding-left: 15vw;
  background-color: #fff;
}

.list {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.list.image {
  padding-right: 0vw;
}

.paragraph-2-copy {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.paragraph-2-copy.image {
  padding-right: 0vw;
}

.paragraph-2-copy {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.paragraph-2-copy.image {
  padding-right: 0vw;
}

.paragraph-2-copy-copy {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.paragraph-2-copy-copy.image {
  padding-right: 0vw;
}

.centered-paragraph {
  margin-bottom: 0px;
  padding-top: 67px;
  padding-bottom: 0px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.centered-paragraph.image {
  padding-right: 0vw;
}

.container-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.centered-paragraph-copy {
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.centered-paragraph-copy.image {
  padding-right: 0vw;
}

.paragraph-2-copy {
  margin-bottom: 0px;
  padding-top: 67px;
  padding-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.paragraph-2-copy.image {
  padding-right: 0vw;
}

.parag {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.parag.image {
  padding-right: 0vw;
}

.parag-copy {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.parag-copy.image {
  padding-right: 0vw;
}

.additional-section {
  display: none;
  padding-right: 15vw;
  padding-left: 15vw;
}

.div-block-16 {
  margin-top: 42px;
}

.nav-color {
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.nav-color:hover {
  color: #698dd3;
}

.nav-color.w--current {
  display: inline-block;
  color: #698dd3;
}

.nav-copy {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.navbar-4 {
  background-color: #fff;
}

.section-10 {
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.grid-4-copy {
  grid-column-gap: 24px;
  -ms-grid-columns: 0.5fr 2.25fr;
  grid-template-columns: 0.5fr 2.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-11 {
  display: block;
  overflow: hidden;
  margin-top: 30vh;
  margin-bottom: 100px;
  padding-bottom: 50px;
}

.form {
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-field-5 {
  margin-right: 16px;
  margin-bottom: 0px;
  padding: 24px;
  border-radius: 30px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.success-message-2 {
  background-color: transparent;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
}

.text-block-13 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.container-6-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #353535;
}

.body-6 {
  background-color: #000;
}

.section-12 {
  margin-top: 15vh;
}

.div-block-18 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-19 {
  margin-top: 10px;
}

.tagg {
  color: #fff;
  text-align: center;
}

.heading-17 {
  margin-top: 24px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.div-block-20 {
  margin-bottom: 16px;
}

.div-block-12-copy {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
}

.tagg-heading-1-gradient-copy {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 60px;
  line-height: 60px;
  font-weight: 700;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.div-block-21 {
  background-color: transparent;
}

.heading-18 {
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.paragraph-4-copy {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 200;
}

.text-block-14 {
  margin-bottom: 24px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.heading-19 {
  font-family: Montserrat, sans-serif;
  color: #c7c7c7;
  font-size: 20px;
}

.h3 {
  color: #fff;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.text-block-15 {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 600;
}

.grid-8 {
  -ms-grid-columns: 0.25fr 0.25fr;
  grid-template-columns: 0.25fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.bold-text-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 18px;
}

.div-block-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bold-text-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog-grid {
  margin-bottom: 24px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.thumbnail-div {
  height: 150px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0px 0px;
  background-size: cover;
}

.cms-blog {
  display: block;
  margin-top: 50px;
}

.collection-list-wrapper {
  padding-bottom: 24px;
}

.blog-title-div {
  height: 180px;
  padding: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #353535;
}

.div-block-24 {
  height: 500px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.information {
  padding: 40px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 1px 1px 11px 0 rgba(0, 0, 0, 0.15);
}

.blog-title {
  margin-top: 8px;
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  color: #fff;
  font-size: 20px;
  line-height: 34px;
  font-style: normal;
  text-decoration: none;
  cursor: pointer;
}

.blog-title:hover {
  color: #89f3ff;
}

.category-2 {
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  color: #919191;
  font-size: 18px;
  font-weight: 700;
}

.content {
  margin-top: 40px;
  padding-right: 30px;
  padding-left: 30px;
}

.rich-text-block {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #e4e4e4;
  font-size: 18px;
  line-height: 30px;
}

.image-19 {
  height: 500px;
}

.div-block-25 {
  height: 500px;
  background-image: url('../images/blog-header.png');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-26 {
  position: relative;
  padding: 50px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.div-block-27 {
  margin-top: 40px;
  margin-bottom: 0px;
  padding-bottom: 50px;
}

.body-blog {
  padding-right: 50px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  text-transform: none;
}

.body-blog h2 {
  margin-bottom: 16px;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
}

.body-blog p {
  margin-bottom: 16px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
}

.body-blog h4 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Montserrat, sans-serif;
}

.body-blog li {
  margin-bottom: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.body-blog strong {
  color: #fff;
  font-size: 24px;
}

.body-blog figcaption {
  color: #fff;
}

.body-blog img {
  width: auto;
  height: 400px;
}

.div-block-23-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-12-copy {
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.form-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-field-5-copy {
  margin-right: 16px;
  margin-bottom: 0px;
  padding: 24px;
  border-radius: 30px;
  background-color: #e9e9e9;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.bold-text-5-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #000;
}

.container-8 {
  margin-bottom: 0px;
  padding-bottom: 50px;
}

.body-7 {
  background-color: #000;
}

.blog-title-2-copy {
  margin-top: 8px;
  color: #fff;
  font-size: 34px;
  line-height: 34px;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  cursor: auto;
}

.blog-title-2-copy:hover {
  color: #fff;
}

.container-9 {
  padding-bottom: 50px;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-9 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-29 {
  margin-right: 50px;
}

.image-20 {
  height: 120px;
}

.container-6-copy {
  display: block;
  margin-top: 50px;
  padding: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #353535;
  background-image: linear-gradient(159deg, #8d21fc, #89b8ff);
}

.grid-10 {
  -ms-grid-columns: 0.5fr 1.75fr;
  grid-template-columns: 0.5fr 1.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-29-copy {
  margin-right: 0px;
}

.container-6-copy-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #353535;
}

.div-block-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-11 {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 42px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 0.25fr;
  grid-template-columns: 0.25fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-31 {
  margin-right: 0px;
}

.heading-20 {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #353535;
  font-size: 63px;
  line-height: 80px;
  font-weight: 700;
}

.text-span-4 {
  background-image: linear-gradient(207deg, #89f3ff, #8d21fc);
  font-weight: 700;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-13 {
  display: none;
  height: 80vh;
  margin-bottom: 42px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url('../images/pexels-photo-4202325.jpeg');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-32 {
  background-color: #000;
}

.section-14 {
  display: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/pexels-photo-4207891.jpeg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/pexels-photo-4207891.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.heading-21 {
  color: #fff;
  text-align: center;
}

.paragraph-5 {
  font-family: Merriweather, serif;
  text-align: center;
}

.paragraph-copy {
  padding-top: 20px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
}

.div-block-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-34 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.section-15 {
  display: block;
  margin-top: 42px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-16-copy {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-weight: 600;
  text-align: center;
}

.grid-12 {
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-35 {
  height: 35vh;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-36 {
  width: 310px;
  height: 310px;
  margin-bottom: 0px;
  border-radius: 8px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-37 {
  margin-bottom: 0px;
  padding: 8px;
}

.div-block-37:hover {
  color: #000;
}

.heading-22 {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.heading-22:hover {
  color: #89f3ff;
}

.heading-23 {
  margin-top: 0px;
  font-family: Montserrat, sans-serif;
  color: #cafaff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.section-16 {
  display: none;
  height: 40vh;
  margin-top: 42px;
  background-image: url('../images/pexels-photo-4202325.jpeg'), url('../images/pexels-photo-4202325.jpeg');
  background-position: 50% 50%, 50% 50%;
  background-size: cover, cover;
}

.link-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  -webkit-transition: color 275ms ease, opacity 200ms ease;
  transition: color 275ms ease, opacity 200ms ease;
  color: #000;
}

.link-block-2:hover {
  color: #7ac2ea;
}

.collection-item-2 {
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.grid-13 {
  width: 80vw;
  height: auto;
  grid-column-gap: 24px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-38 {
  height: 70vh;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.section-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.paragraph-color {
  padding-top: 20px;
  font-family: Montserrat, sans-serif;
  color: #c7c7c7;
  font-size: 15px;
  line-height: 29px;
  text-align: left;
}

.option-list {
  padding-right: 0vw;
  font-family: Montserrat, sans-serif;
}

.default-state {
  margin-top: 20px;
  padding-right: 20vw;
  padding-left: 0px;
}

.select-field {
  margin-bottom: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #353535;
  background-color: #353535;
  color: #fff;
}

.container-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cart-button {
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: background-color 275ms ease;
  transition: background-color 275ms ease;
}

.cart-button:hover {
  border-radius: 2px;
  background-color: rgba(122, 194, 234, 0.35);
}

.icon-6 {
  color: #000;
}

.text-block-16 {
  color: #000;
}

.cart-quantity {
  color: #000;
}

.div-block-39 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10vw;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.div-block-40 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-21 {
  width: 5px;
  height: 5px;
  margin-right: 6px;
}

.text-block-11-copy {
  margin-right: 8px;
  font-family: Montserrat, sans-serif;
  color: #c7c7c7;
  font-size: 15px;
  font-weight: 500;
}

.background-video-4 {
  width: 100vw;
  height: 10vh;
}

.container-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cart-button-2 {
  background-color: transparent;
  color: #fff;
}

.cart-button-2:hover {
  border-radius: 8px;
  background-color: rgba(122, 194, 234, 0.23);
}

.cart-quantity-2 {
  color: #000;
}

.div-block-23-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-11-copy {
  display: block;
  margin-top: 0vh;
  margin-bottom: 0px;
  padding-bottom: 50px;
}

.slide-nav {
  width: auto;
  border: 1px solid transparent;
  color: #c7c7c7;
}

.grid-14 {
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
}

.grid-15 {
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-12 {
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.div-block-41 {
  height: auto;
}

.image-22 {
  height: 70%;
}

.grid-16 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-23 {
  height: 70%;
}

.slider-2 {
  background-color: transparent;
}

.slider-3 {
  height: 540px;
  background-color: transparent;
}

.container-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-17 {
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.icon-7 {
  color: #c7c7c7;
}

.icon-8 {
  color: #c7c7c7;
}

.section-18 {
  background-color: #000;
  line-height: 25px;
}

.blog-subscribe {
  display: block;
  margin-top: 0vh;
  margin-bottom: 0px;
  padding-bottom: 50px;
  background-color: #000;
}

.grid-18 {
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-16-copy {
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 600;
}

.div-block-42 {
  margin-bottom: 8px;
}

.image-24 {
  width: 166px;
  height: 166px;
  border-radius: 8px;
}

.image-25 {
  padding-right: 0px;
  padding-left: 12px;
}

.image-26 {
  padding-right: 12px;
}

.image-27 {
  padding-right: 12px;
  padding-left: 12px;
}

.text-block-17 {
  margin-bottom: 30px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.link-2 {
  color: #fff;
}

.link-2:hover {
  color: #89f3ff;
}

.text-block-11-copy {
  margin-right: 6px;
  font-family: Montserrat, sans-serif;
  color: #c7c7c7;
  font-size: 15px;
  font-weight: 500;
}

.tagged-in {
  display: none;
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.tagged-in:hover {
  color: #698dd3;
}

.tagged-in.w--current {
  color: #698dd3;
}

.tagged-in-color {
  display: none;
  -webkit-transition: color 275ms ease;
  transition: color 275ms ease;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.tagged-in-color:hover {
  color: #698dd3;
}

.tagged-in-color.w--current {
  display: inline-block;
  color: #698dd3;
}

.image-29 {
  height: 26px;
}

.card-number {
  display: none;
}

.column {
  display: none;
}

.column-2 {
  display: none;
}

.billing-address-toggle {
  display: none;
}

.text-block-18 {
  display: none;
}

.billing-address {
  display: none;
}

.shipping-method {
  display: none;
}

.field-label-2 {
  font-weight: 400;
}

.field-label-3 {
  font-size: 12px;
  font-weight: 400;
}

.shipping-method-2 {
  display: none;
}

.block-content {
  display: none;
}

.block-header {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-24 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.grid-19 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.web-payments {
  display: none;
}

.field-label-4 {
  color: #fff;
}

.field-label-5 {
  color: #fff;
}

.quantity {
  margin-bottom: 20px;
  border-color: #353535;
  background-color: #353535;
  color: #fff;
}

.collection-list-wrapper-2 {
  margin-top: 42px;
}

.body-8 {
  background-image: url('../images/2.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.collection-list {
  margin-right: 0px;
  margin-left: 0px;
}

.collection-list-wrapper-3 {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.grid-20 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-30 {
  width: 100px;
}

.collection-list-wrapper-4 {
  display: block;
}

.body-career {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: #fff;
}

.grid-21 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.collection-list-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.collection-item-3 {
  margin-right: 24px;
}

.empty-state {
  background-color: #000;
  color: #000;
}

.grid-22 {
  -ms-grid-columns: 1.5fr 0.25fr;
  grid-template-columns: 1.5fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-43 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-44 {
  margin-bottom: 10px;
}

.heading-16-copy-copy {
  margin-top: 8px;
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.product-title {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 23px;
  font-weight: 400;
  text-align: center;
}

.grid-23 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.extra-item {
  display: none;
}

.web-payments-2 {
  display: none;
}

.extra-item-2 {
  display: none;
}

@media screen and (max-width: 991px) {
  .tagg-intro-section {
    padding-right: 10vw;
    padding-left: 10vw;
  }

  .tagg-intro-hero {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .tagg-intro-container {
    padding-top: 17vh;
    padding-right: 0vw;
  }

  .navlink {
    font-size: 30px;
  }

  .navlink {
    width: 100%;
    height: 100px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 0px;
  }

  .tagg-heading-1 {
    text-align: center;
  }

  .tagg-paragraph {
    text-align: center;
  }

  .interaction-div {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .icon {
    color: #fff;
    font-size: 35px;
  }

  .tagg-mockup {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    padding-top: 74px;
    padding-right: 0px;
  }

  .image-3 {
    height: 480px;
  }

  .nav-menu {
    z-index: 100;
    width: 100%;
    height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background-color: #353535;
    background-image: linear-gradient(219deg, #89f3ff, #8d21fc);
  }

  .heading {
    line-height: 130px;
  }

  .our-values-section {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .value-content-images {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .blur-glass-div {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: auto;
  }

  .heading-3 {
    line-height: 60px;
  }

  .news {
    padding-right: 10vw;
    padding-left: 10vw;
  }

  .tagg-text-01 {
    padding-right: 10vw;
    padding-left: 10vw;
  }

  .error-message {
    top: -388px;
  }

  .menu-button {
    background-color: transparent;
    -webkit-transition: all 800ms ease-in-out;
    transition: all 800ms ease-in-out;
  }

  .menu-button.w--open {
    background-color: transparent;
  }

  .icon-4 {
    color: #8d21fc;
  }

  .image-7 {
    width: auto;
    height: 250px;
    max-height: none;
    max-width: none;
  }

  .collection-item {
    padding-right: 13px;
    padding-left: 13px;
  }

  .div-block-5 {
    width: 160px;
    height: 60%;
    font-size: 13px;
  }

  .text-block-6 {
    font-size: 15px;
  }

  .text-block-7 {
    font-size: 14px;
  }

  .nav {
    background-color: #000;
    text-align: center;
  }

  .menu-button-2.w--open {
    background-color: #000;
  }

  .nav-menu-2 {
    background-color: #000;
  }

  .section-4 {
    margin-top: 220px;
  }

  .icon-5 {
    color: #fff;
  }

  .background-video-3 {
    width: 40vw;
    height: 75vh;
    margin-top: -200px;
  }

  .tagg-heading-1-gradient {
    text-align: center;
  }

  .nav-color {
    background-color: #000;
    text-align: center;
  }

  .nav-copy {
    background-color: #000;
    text-align: center;
  }

  .menu-button-black.w--open {
    background-color: #000;
  }

  .icon-5-copy {
    color: #fff;
  }

  .tagg-heading-1-gradient-copy {
    text-align: center;
  }

  .tagged-in {
    background-color: #000;
    text-align: center;
  }

  .tagged-in-color {
    background-color: #000;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .tagg-intro-section {
    padding-right: 10vw;
    padding-left: 10vw;
  }

  .tagg-intro-container {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .tagg-heading-1 {
    font-size: 50px;
  }

  .tagg-paragraph {
    font-size: 18px;
    font-weight: 400;
  }

  .button {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
  }

  .image-2 {
    height: 55px;
    max-height: 100%;
  }

  .tagg-mockup {
    width: auto;
  }

  .heading {
    font-size: 80px;
    line-height: 100px;
  }

  .our-value-contents {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .value-content-text {
    width: 100%;
  }

  .value-content-images {
    width: 100%;
    height: 100%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .slider {
    padding-right: 43px;
    padding-left: 43px;
  }

  .app-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .news {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .news-container {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .text-block-2 {
    font-size: 20px;
    line-height: 26px;
  }

  .text-container {
    padding-right: 21px;
  }

  .button-2 {
    width: 150px;
    font-size: 16px;
  }

  .error-message {
    top: -460px;
  }

  .image-7 {
    height: 50%;
  }

  .div-block-5 {
    width: 110px;
  }

  .text-block-6 {
    font-size: 12px;
  }

  .text-block-7 {
    font-size: 12px;
  }

  .grid-3 {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .background-video-3 {
    margin-top: -200px;
  }

  .tagg-heading-1-gradient {
    font-size: 50px;
  }

  .button-blog {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
  }

  .tagg-heading-1-gradient-copy {
    font-size: 50px;
  }
}

@media screen and (max-width: 479px) {
  .tagg-intro-section {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .tagg-intro-container {
    padding-right: 0vw;
    padding-left: 0vw;
  }

  .tagg-heading-1 {
    font-size: 40px;
    line-height: 50px;
  }

  .tagg-paragraph {
    font-weight: 400;
  }

  .button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    padding: 15px 16px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }

  .interaction-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-2 {
    width: 250px;
    height: auto;
    padding-left: 0px;
  }

  .icon {
    font-size: 30px;
  }

  .tagg-mockup {
    width: auto;
  }

  .image-3 {
    min-width: 100%;
  }

  .nav-menu {
    width: 100%;
    height: 290px;
  }

  .bottom-bar {
    position: static;
    left: 0vw;
    right: 0vw;
    width: 100%;
    height: 190px;
    margin-left: 0px;
    padding-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .link {
    font-size: 14px;
  }

  .career-intro-text {
    width: 90%;
  }

  .heading {
    background-color: #89f3ff;
    background-image: none;
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
  }

  .text-block {
    padding-top: 22px;
  }

  .carerrs-statement {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .slider {
    padding-right: 0px;
    padding-left: 0px;
  }

  .slide-title {
    font-size: 30px;
  }

  .heading-2 {
    text-align: center;
  }

  .icon-2 {
    display: block;
    opacity: 0.21;
    color: #8d21fc;
  }

  .icon-3 {
    display: block;
    opacity: 0.21;
    color: #8d21fc;
  }

  .blur-glass-div {
    height: auto;
  }

  .app-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .news-container {
    padding-top: 3vh;
    padding-bottom: 3vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-conatiner {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-container {
    width: auto;
    height: 200px;
  }

  .text-block-2 {
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  .text-container {
    padding-right: 10px;
    padding-bottom: 0px;
  }

  .button-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 40px;
  }

  .tagg-team-intro-container {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .heading-4 {
    margin-bottom: 0px;
    padding-bottom: 10px;
    font-size: 40px;
    line-height: 40px;
  }

  .text-block-3 {
    padding-top: 32px;
  }

  .team-section {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .bold-text-2 {
    font-size: 20px;
  }

  .what-you-l-get-section {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .what-you-ll-get-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .what-you-ll-get-container {
    width: 100%;
  }

  .what-you-ll-get-image {
    height: 370px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .contact-bg {
    padding-right: 15vw;
    padding-left: 15vw;
  }

  .heading-5 {
    font-size: 40px;
  }

  .tagg-text-01 {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .div-block-3 {
    background-color: rgba(53, 53, 53, 0.7);
  }

  .heading-6 {
    text-align: center;
  }

  .bold-text-3 {
    font-size: 30px;
    line-height: 30px;
  }

  .paragraph-3 {
    font-size: 16px;
    text-align: center;
  }

  .paragraph-3._2 {
    font-size: 16px;
  }

  .bold-text-4 {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }

  .bold-text-4._2 {
    line-height: 50px;
  }

  .heading-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 40px;
    line-height: 30px;
  }

  .tagg-text-02 {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .contact-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .icon-4 {
    color: #8d21fc;
  }

  .jobs-div {
    padding-right: 11px;
    padding-left: 11px;
  }

  .job-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-5 {
    height: 50%;
  }

  .text-block-6 {
    text-align: center;
  }

  .image-8 {
    width: 30vw;
  }

  .nav {
    background-color: #000;
    text-align: center;
  }

  .menu-button-2 {
    /*border-style: solid;
    border-width: 1px;*/
    /*border-color: #fff;
    background-color: #fff;*/
    color: #000;
  }

  .menu-button-2.w--open {
    background-color: #c7c7c7;
  }

  .heading-9 {
    margin-bottom: 0px;
    font-size: 64px;
    line-height: 70px;
    font-weight: 700;
  }

  .heading-10 {
    font-size: 24px;
  }

  .div-block-6 {
    overflow: visible;
  }

  .div-block-7 {
    left: auto;
    bottom: auto;
  }

  .image-10 {
    height: 250px;
  }

  .div-block-8 {
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    height: 30vh;
    padding-top: 400px;
    padding-bottom: 0px;
  }

  .section-3 {
    overflow: hidden;
    margin-top: 0px;
  }

  .lottie-animation {
    margin-top: 24px;
  }

  .image-12 {
    height: 100px;
  }

  .grid-2 {
    grid-auto-flow: column;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .footer-link {
    cursor: pointer;
  }

  .section-4 {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .grid-3 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto;
  }

  .image-15 {
    height: 5vh;
  }

  .icon-5 {
    border-radius: 2px;
    background-color: transparent;
    color: #fff;
  }

  .background-video-3 {
    width: 150px;
    height: 300px;
    margin-top: -63vh;
  }

  .section-6 {
    background-position: 50% 50%;
    background-size: cover;
  }

  .tagg-heading-1-gradient {
    font-size: 40px;
    line-height: 50px;
  }

  .grid-4 {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .heading-16 {
    margin-bottom: 20px;
    text-align: center;
  }

  .image-17 {
    width: 140px;
  }

  .paragraph-4 {
    text-align: center;
  }

  .grid-5 {
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .grid-6 {
    padding-right: 16px;
    padding-left: 16px;
    grid-column-gap: 24px;
  }

  .grid-7 {
    justify-items: stretch;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .button-blog {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    padding: 15px 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 16px;
  }

  .additional-section {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .div-block-16 {
    margin-top: 32px;
  }

  .nav-color {
    background-color: #fff;
    text-align: center;
  }

  .nav-copy {
    background-color: #000;
    text-align: center;
  }

  .grid-4-copy {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .section-11 {
    margin-top: 20vh;
  }

  .form {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .text-field-5 {
    margin-right: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .div-block-17 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .menu-button-black {
    border: 1px solid #000;
    background-color: #000;
    color: #fff;
  }

  .menu-button-black.w--open {
    background-color: #353535;
    color: #fff;
  }

  .icon-5-copy {
    border-radius: 2px;
    background-color: transparent;
    color: #000;
  }

  .div-block-20 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tagg-heading-1-gradient-copy {
    font-size: 40px;
    line-height: 50px;
  }

  .text-block-14 {
    line-height: 25px;
    text-align: center;
  }

  .heading-19 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .div-block-24 {
    height: 205px;
    background-position: 50% 50%;
    background-size: cover;
  }

  .rich-text-block {
    color: #fff;
    font-size: 15px;
  }

  .div-block-25 {
    background-position: 45% 50%;
  }

  .div-block-27 {
    margin-bottom: 40px;
  }

  .body-blog {
    padding-right: 24px;
    padding-left: 24px;
  }

  .body-blog p {
    font-size: 13px;
  }

  .body-blog li {
    font-size: 13px;
  }

  .body-blog img {
    height: auto;
  }

  .form-copy {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .text-field-5-copy {
    margin-right: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .blog-title-2-copy {
    line-height: 35px;
  }

  .grid-10 {
    -ms-grid-columns: 1.75fr;
    grid-template-columns: 1.75fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .grid-11 {
    grid-row-gap: 30px;
    -ms-grid-columns: 0.75fr;
    grid-template-columns: 0.75fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .heading-20 {
    font-size: 43px;
    line-height: 50px;
  }

  .div-block-34 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .heading-16-copy {
    margin-bottom: 20px;
  }

  .collection-item-2 {
    padding-right: 8px;
    padding-left: 8px;
  }

  .grid-13 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-block-38 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .left-arrow {
    display: block;
  }

  .grid-17 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .container-14 {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .grid-18 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .heading-16-copy {
    margin-bottom: 20px;
    text-align: center;
  }

  .div-block-42 {
    margin-bottom: 0px;
  }

  .image-27 {
    padding-top: 42px;
  }

  .tagged-in {
    background-color: #000;
    text-align: center;
  }

  .tagged-in-color {
    background-color: #fff;
    text-align: center;
  }

  .image-28 {
    margin-top: 42px;
  }

  .body-8 {
    background-attachment: fixed;
  }

  .grid-20 {
    display: block;
  }

  .heading-16-copy-copy {
    margin-bottom: 20px;
  }

  .product-title {
    margin-bottom: 20px;
  }
}

#w-node-b5027ec2-01bf-4cd9-0d09-f654f93a1369-8ca299e2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_19a17055-d973-8f07-b5f4-daeee5add163-8ca299e2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_0420ce15-5395-d1fe-7d38-be056434b61b-8ca299e2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_0420ce15-5395-d1fe-7d38-be056434b61d-8ca299e2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-dcd9c09b-75ab-6d88-f0d4-ca01afa96743-8ca299e2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ef70c4c9-34be-0a2d-70f2-75a5d531d2e0-eb3f9193 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6a9776fc-350a-b348-606a-03efee1e15b3-eb3f9193 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_199afce9-4099-f3fc-cbe6-bec8e9cd9b8b-e8daa3ea {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95a3ee03-089d-b19f-c3a1-00eb82882650-e8daa3ea {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-fae2b7d9-0c6a-a4aa-1517-028e205ea83d-e8daa3ea {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7cf8e78e-b360-2c8a-a172-30aa27f112a0-e8daa3ea {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-c85358db-4bc8-6a44-ee52-07f47b0b20f4-2bdd0bc1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4f0607ed-eb61-af3d-fefd-7d22a56c2ef3-2bdd0bc1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9681e908-e97d-caa7-82e6-46db1aeb6cee-2bdd0bc1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0a4e3d59-ab78-2a09-e34b-047c772bd65b-2bdd0bc1 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

@media screen and (max-width: 479px) {
  #w-node-_7cf8e78e-b360-2c8a-a172-30aa27f112a0-e8daa3ea {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

/**/
@font-face {
font-family: 'BubbleGum';
src: url('../fonts/BubbleGum.svg#BubbleGum') format('svg'),
url('../fonts/BubbleGum.ttf') format('truetype'),
url('../fonts/BubbleGum.woff') format('woff');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Bubblegum';
src: url('../fonts/Bubblegum.eot');
src: url('../fonts/Bubblegum.eot?#iefix') format('embedded-opentype'),
url('../fonts/Bubblegum.woff2') format('woff2');
font-weight: normal;
font-style: normal;
}

.navbar {
     top: 10px;}
.section-3 {position: absolute;top: 0;right: 0;left: 0;z-index: 1;}
.no-img{ background-image: none !important;     z-index: 9; }
.rightNav {width: auto;}
.mainNav {     padding-right: 15vw; }
.rightNav a.nav-color {color: #fff;}
.bannerBG .div-block-6{ max-height: 750px; overflow: hidden; }
.banner h1{font-family: 'BubbleGum';color: #fff;      line-height: 70px;    font-size: 70px;   font-weight: normal; }
.banner p{font-family: 'Montserrat', sans-serif;font-weight: 600; color: #fff; text-align: center;}

.banner .tagg-mockup img.image-3 {height: auto;}
img.image-logo {height: auto; margin: 0 auto;}
.banner .tagg-intro-container {padding-right: 25vw;}
.banner .tagg-mockup {width: 40vw;}
.banner .interaction-div{ justify-content: center; }
.banner .interaction-div a.button { margin-right: 30px;text-align: center;width: 270px;background: linear-gradient(95.17deg, #8F01FF -120.35%, #6EE7E7 91.3%);
}
.rcc-section {margin-top: 30vh;}
.rcc-content {background: linear-gradient(180deg, #0A004E 0%, #7B00E5 97.46%);box-shadow: -4px 4px 10px rgb(90 90 90 / 50%);border-radius: 10px;
padding: 60px 70px;}
.rcc-content h2 {color: #fff;font-family: 'Montserrat', sans-serif;font-style: normal;font-weight: 900;font-size: 36px;line-height: 46px; margin-top: 0px;
background: linear-gradient(92.08deg, #8F01FF -1.71%, #6EE7E7 88.97%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.rcc-content  p {font-family: 'Montserrat', sans-serif;font-style: normal;font-weight: 600;font-size: 24px; color: #fff;line-height: 36px;}
.section-11.Aso {margin-top: 10vh;}
.sbl-section a.button.w-button {text-align: center;}

.footer .w-layout-grid.grid-3 {
    /*grid-template-columns: 1fr 1fr 1fr 1fr ;*/
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
}

.footer .footer-link {
    padding: 0 20px;
}
.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.social-icons a{ padding: 0 20px; }


/*responsive*/
@media screen and (max-width:1330px){
  .bannerBG .div-block-6 {
      max-height:600px;
  }
  }
@media screen and (min-device-width: 1026px) and (max-device-width: 1200px) { 

.banner h1 {
     line-height: 50px;
    font-size: 50px;padding-bottom: 20px;
 }
.banner p {
     padding-bottom: 20px;
}

.bannerBG .div-block-6 {
    max-height: 585px;
 }
.tagg-intro-hero{     height: 70vh; }
.rcc-section {
    margin-top: 20vh;
}


/**/

}

@media screen and (max-width:1070px){
  .bannerBG .div-block-6 {
      max-height:560px;
  }
  }


@media screen and (min-device-width: 992px) and (max-device-width: 1025px) { 

.banner h1 {
     line-height: 40px;
    font-size: 40px;padding-bottom: 20px;
 }
.banner p {
     padding-bottom: 20px;
}
.rcc-section {
    margin-top: 10vh;
}
.bannerBG .div-block-6 {
    max-height: 560px;
 }

.rcc-content {
     margin: 0 15px;
}

.Aso .w-layout-grid.grid-18 {
    padding: 30px 15px 0;
}

.tagg-intro-hero{     height: 70vh; }

/**/
}
@media screen and (max-width: 991px){

  .navbar { top:  0px;}
  .nav-menu{ height: auto; }
  .bannerBG .div-block-6 {  max-height: 490px;  }
  .rightNav a.nav-color {  color: #000;   }
  .rightNav a.nav-color:hover {  color: #698dd3;  }
  .nav-color {  background-color: #fff;  }
  .section-4 {  margin-top: 50px;  }
  .rcc-section {  margin-top: 50vh;  }
  .banner .tagg-intro-hero{    flex-direction: row;}
  .banner .tagg-intro-container {  width: 70%;  padding-right: 0;  }
  .banner .tagg-intro-container {  padding-top: 20vh;  }
    .banner h1 {  line-height: 42px;  font-size: 40px;  padding: 0;  }
  .banner p {        font-size: 20px;  padding-bottom: 10px;    }
  .banner .interaction-div a.button {  margin-right: 5px;  padding: 5px 11px;  font-size: 15px;  line-height: 27px;  width: auto;  }
  .banner .image-2 {  width: 120px;    height: auto;  }
  .banner .tagg-mockup {  width: 25vw;  }
  .Aso .image-24 {  height: auto;  }
  .bannerBG .div-block-6 {
    max-height:480px;
}

}



@media screen and (max-width: 911px){
  .nav-menu {height: auto;}
  .image-3 {height: 380px;}
  .bannerBG .div-block-6 {max-height: 470px;}
  .rightNav a.nav-color {color: #000; }
  .rightNav a.nav-color:hover {color: #698dd3;}
  .nav-color {background-color: #fff;}
  .section-4 {margin-top: 50px;}
  .rcc-section {margin-top: 50vh;}
  .banner .tagg-intro-hero{    flex-direction: row;}
  .banner .tagg-intro-container {width: 70%;padding-right: 0;}
  .banner .tagg-intro-container {padding-top: 20vh;}
  .banner h1 {line-height: 42px;font-size: 40px;padding: 0;}
  .banner p {        font-size: 20px;padding-bottom: 10px;  }
  .banner .interaction-div a.button {margin-right: 5px;padding: 5px 11px;font-size: 15px;line-height: 27px;width: auto;}
  .banner .image-2 {width: 120px;    height: auto;}
  .banner .tagg-mockup {width: 25vw;}



/**/
}


@media screen and (max-width: 840px){

.banner .tagg-intro-container {padding-top: 8vh;}
.bannerBG .div-block-6 {max-height: 440px;}
.rcc-section {margin-top: 15vh;}

/**/
}

@media screen and (max-width: 800px){  
  .bannerBG .div-block-6 {max-height: 430px;}



/**/
}


@media screen and (max-width:767px){

a.brand.w-nav-brand img {max-width: 70px;}
.banner .tagg-mockup img.image-3 {height: auto;max-width: 170px;min-width: auto;}

.bannerBG .div-block-6 {max-height: 370px;    height: 100%;}
.banner .tagg-intro-hero{    flex-direction: column;}
.banner .tagg-intro-container {width: 100%;padding: 0;}
.banner .tagg-intro-container {padding-top: 20vh;}
.banner h1 {line-height: 40px;font-size: 40px;padding-bottom: 15px;margin-bottom: 0;  }
.banner p { font-size: 20px; padding-bottom: 15px;  }
.banner .interaction-div a.button {margin-right: 5px;padding: 8px 21px;font-size: 15px;line-height: 27px;width: auto;}
.banner .image-2 {width: 120px;    height: auto;}
.banner .tagg-mockup {width: 100%; display: none;}
.rcc-section {margin-top: 20vh;}
.rcc-content {padding: 30px 30px;margin: 0 15px;}
.rcc-content h2 {line-height: 36px;font-size: 26px;}
.rcc-content p {font-size: 16px;line-height: 26px;}
.Aso a.w-inline-block {max-width:inherit;}
.Aso .image-24 {width: 166px;height: auto;}
.Aso .w-layout-grid.grid-18 {padding: 0 15px; margin-top: 30px;}
.footer .grid-3 {grid-template-columns: 1fr;}
.Aso .grid-18 a {text-align: center;}



/**/
}


@media screen and (max-width:741px){
.bannerBG .div-block-6{ max-height: 389px; }
.rcc-section{ margin-top:20vh; }



/**/
}

@media screen and (max-width:680px){
.bannerBG .div-block-6{ max-height: 315px; }
 
/**/
}
@media screen and (max-width:560px){
  .bannerBG .div-block-6 {
  max-height: 280px;
}
}
@media screen and (max-width:500px){
  .bannerBG .div-block-6 {
  max-height: 220px;
}
}
@media screen and (max-width:479px){


.bannerBG .div-block-6 {max-height:210px;height: 100%;}
.Aso .grid-18 a img {max-width: 100%;}
.Aso .grid-18 a {text-align: center;}
.banner .tagg-intro-container {width: 100%;padding: 0;}
.banner .tagg-intro-container {padding-top: 10vh;}
.banner h1 {line-height: 30px;font-size: 30px;    padding-bottom: 15px;margin-bottom: 0;    margin-top: 10px;}
.banner p {    font-size: 16px;padding-bottom: 15px;    margin-bottom: 0;}
.banner .interaction-div a.button {margin-right: 5px;padding: 8px 0px;font-size: 12px;line-height: 23px;width: 50%;}
.banner .image-2 {width: 120px;}
.banner .tagg-mockup {width: 100%;}
.rcc-section {margin-top: 10vh;}
.rcc-content {padding: 30px 30px;margin: 0 15px;}
.rcc-content h2 {line-height: 36px;font-size: 26px;}
.rcc-content p {font-size: 16px;line-height: 26px;}
.Aso a.w-inline-block {max-width: 50%;}

/**/
}

@media screen and (max-width:400px){
  .bannerBG .div-block-6 {
  max-height: 180px;
}
}

@media screen and (max-width:376px){

  .bannerBG .div-block-6 {max-height:170px;}



/**/
}



html.w-mod-touch * {
    background-attachment: scroll !important;
}