.pfBox{ display: none; }

@media screen and (max-width:767px) {
  .pfBox{ display: block;width: 100%; padding: 10px 15px; position: absolute; z-index: 9999; display: flex;align-items: center; 
    justify-content: space-between; background:rgba( 0 0 0 / 80%);}
  .pfLeftBox {display: flex; align-items: center;}
  .pfLeftBox a img{ margin-right: 10px;width: 54px;height: 54px;object-fit: cover;border-radius: 50px; }
  .pfLeftBox h3{ margin: 0px; color: #fff; font-size: 15px; text-transform: capitalize;text-align: left;font-weight: 600;line-height: 18px;
   font-family: 'SF Pro Display', sans-serif; }
  .pfLeftBox p{ color: #fff; font-family: 'SF Pro Display', sans-serif; font-size: 14px;font-weight: 500; line-height: 17px;text-align: left; 
  margin-top: 5px;}
  .pfLeftBox .pfText{padding-top: 10px;}
   .btnopenApp{   font-size: 15px;font-weight: 600;line-height: 21px; color: #fff; background: #698DD3;padding: 8px 18px;
  letter-spacing: 0em;border-radius: 5px;text-align: center;}
 
  .btnopenApp:hover {background-color: #698DD3; color: #fff;
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
   }
}