@font-face {
  font-family: 'SF Pro Text Bold';
  src: url('assets/fonts/SFPRODISPLAYBOLD.woff');
  src: url('assets/fonts/SFPRODISPLAYBOLD.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text Medium';
  src: url('assets/fonts/SFPRODISPLAYMEDIUM.woff');
  src: url('assets/fonts/SFPRODISPLAYMEDIUM.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text Regular';
  src: url('assets/fonts/SFPRODISPLAYREGULAR.woff');
  src: url('assets/fonts/SFPRODISPLAYREGULAR.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
body {
  background-color: #F0F0F0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
.menu_pages_inner::-webkit-scrollbar {
  width:0px;
  padding:0px;
}
.makeStyles-root-301.menu_pages_inner.css-w4z10b-MuiStack-root {
  padding: 0;
}

.makeStyles-root-301.menu_pages.swiper-no-swiping {
  padding: 16px 10px 0;
}
.swiper {
  width: 100%;
}
.grid-scroll {
  overflow: scroll;
  height: 100vh;
}
.loader {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.spinner {
  width: 100px;
  text-align: center;
  margin: auto;
}

.spinner > div {
  width: 25px;
  height: 25px;
  background-color: #698DD3;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

a{
  -webkit-tap-highlight-color: transparent;
}

/* For swiper */
.swiper-slide {
  opacity: 0!important;
}

.swiper-slide-visible {
  opacity: 1!important;
}
.css-view-1dbjc4n, .css-1dbjc4n {flex-basis: 100%;}

.slick-slider{
  touch-action: auto!important;
}